<template>
    <teleport to='#navSlot'>
        <div :style='{ display: "flex" }' v-if='!["viewer", null].includes(role)'>
            <router-link to='/admin'>פעולות</router-link>
            <router-link to='/admin/results'>תוצאות: חולים בעבר</router-link>
            <router-link to='/admin/results/active'>תוצאות: חולים פעילים</router-link>
            <router-link to='/admin/comments' v-if='role === "admin"'>הערות</router-link>
        </div>
    </teleport>
    <router-view />
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";
import { getRole } from "../helpers";

export default defineComponent({
    computed: { role: getRole },
})
</script>